import React, { useEffect } from 'react';
import VerticalTabs from '../common/VerticalTabs';
import Paper from '../common/layout/Paper';
import { styled } from 'styled-components';

export default function PolicyScreen() {
	const [tabValue, setTabValue] = React.useState(0);

	const tabs = [
		{
			label: 'Terms of Service',
			content: <TermsAndConditions />,
		},
		{ label: 'Privacy Policy', content: <PrivacyPolicy /> },
		{
			label: 'Acceptable Use of Policy',
			content: <AcceptableUsePolicy />,
		},
	];

	return (
		<div>
			<VerticalTabs tabs={tabs} value={tabValue} setValue={setTabValue} />
		</div>
	);
}

const PrivacyPolicy = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<StyledPaper>
			<div
				// @ts-ignore
				name="termly-embed"
				data-id="43ae3e4d-d34b-4054-b683-460fec3453f3"
			></div>
		</StyledPaper>
	);
};

const TermsAndConditions = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<StyledPaper>
			<div
				// @ts-ignore
				name="termly-embed"
				data-id="e2e7ea73-5b1c-4f14-b8b7-eacf9c7fd40c"
			></div>
		</StyledPaper>
	);
};

const AcceptableUsePolicy = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<StyledPaper>
			<div
				// @ts-ignore
				name="termly-embed"
				data-id="bf9fe16b-112e-4a71-86ee-ffd31f4cf8f8"
			></div>
		</StyledPaper>
	);
};

const StyledPaper = styled(Paper)`
	background-color: white !important;
`;

const loremIpsum = `Lorem ipsum dolor sit amet consectetur. Augue leo odio sit nunc lacus vitae integer enim consectetur. Aenean ac euismod proin bibendum hac arcu ullamcorper nisl commodo. Libero urna ullamcorper dictum congue rutrum ullamcorper libero egestas egestas. Nunc odio massa at tincidunt orci. Ut pellentesque consequat in morbi odio rhoncus ut duis egestas. Ultrices id velit sollicitudin eget ac fusce. Nulla amet non ac nibh consectetur dictum vitae. Duis iaculis rhoncus ridiculus odio aliquam purus in. Sagittis pellentesque amet amet ac a et mauris commodo nulla. Neque dui velit elit diam libero in tempor.
Integer vestibulum tortor tortor ac dictum. Malesuada rhoncus vitae turpis sit laoreet lectus viverra pharetra fermentum. Arcu interdum varius rhoncus ornare integer. Egestas turpis tempor tellus hendrerit aenean feugiat ut non id. Proin magna tellus commodo mattis donec tincidunt rhoncus tortor. In dictum velit ligula duis eu consectetur id. Odio nulla netus amet facilisis viverra fringilla tempus non urna. Venenatis turpis imperdiet tortor et tristique tortor elementum turpis consectetur. Non imperdiet molestie sit ut at fames vulputate in amet. Malesuada cursus tellus adipiscing donec volutpat metus sed. Mi massa urna scelerisque tortor odio ultrices elementum massa. Eget eleifend nulla neque volutpat at bibendum. Est aliquet venenatis sollicitudin pharetra ipsum ultricies. Sollicitudin diam nibh proin sed dui.
Adipiscing malesuada vel elit iaculis auctor sagittis vulputate suspendisse. In pellentesque non amet at pharetra diam at tristique id. Magna ut tortor arcu porttitor dolor. Nullam dui pulvinar a id. Interdum id nascetur in aliquet sed. Habitant nisi diam non amet mauris. Quam condimentum eu suspendisse vestibulum. Nisl vulputate adipiscing vehicula adipiscing ac neque libero amet. Ut ipsum et non eget mauris tortor lacinia aliquam mattis. Tellus auctor dui massa elit. Elit amet tincidunt est odio lectus imperdiet aliquam tristique. Massa laoreet quam adipiscing neque ipsum. Velit at velit nisi tincidunt faucibus habitant.
Sit ullamcorper tempor sed porttitor arcu sociis felis. Aenean sem pretium mattis purus facilisis consequat amet gravida. Viverra id amet nunc sed consequat magna donec risus. Elementum volutpat eget ac risus massa mauris tempus. Quis nisi urna adipiscing ultrices nec posuere sed senectus. Elementum augue mollis donec nulla adipiscing egestas. Scelerisque adipiscing gravida dui nibh velit cras. Ullamcorper lobortis velit nunc varius euismod. Fermentum tellus egestas non feugiat senectus dignissim sed vitae est. At enim justo nisl pulvinar. Feugiat pharetra euismod id erat accumsan sit feugiat aliquet. Nunc ipsum at eu varius nunc pharetra. Egestas id vulputate tempor tempor adipiscing nunc. Etiam aliquet adipiscing tempor nisl nunc ut ut augue.
Aliquet eget id morbi venenatis pharetra odio purus nibh. Scelerisque lacus et cursus semper nunc in lacus. Eu tincidunt eu lectus cursus id aliquam scelerisque. Bibendum fringilla varius neque morbi eget facilisi elit tempor. Risus elementum et felis euismod gravida quam urna fermentum. Volutpat vitae amet gravida et velit elit quam quam. Odio dictum tellus dignissim tellus praesent viverra in. Congue faucibus porttitor massa laoreet morbi orci est.

Faucibus at vulputate ut id consequat tristique vulputate sagittis pellentesque. Enim ultricies turpis convallis nibh non. Egestas malesuada arcu tristique tristique bibendum et porttitor dis sapien. Cras velit porttitor risus at bibendum malesuada amet. At in in magna turpis urna at ultrices sed. Vestibulum massa odio in varius ac ac porttitor sed diam. Diam ac ac interdum tempor amet sit facilisis elit. Quis risus in diam vel morbi tristique varius. Congue pellentesque dapibus suscipit vitae lacinia nisl. Cursus est mi sem ut.
Sit sit hendrerit pellentesque sit ligula. Tristique amet posuere diam ut donec duis massa. Eu amet enim in quisque cras egestas. Non in maecenas ipsum at vitae. Ut tortor nulla ornare tristique gravida felis ac. At sed sed at tellus. Commodo est metus felis est viverra viverra sit nisi. Aliquet et id maecenas mi. Nam nunc augue sit mauris ridiculus hendrerit lobortis sollicitudin at. Maecenas consequat donec consectetur pretium euismod egestas nec urna. Et blandit porttitor enim eu enim nulla consequat.
Ut sit metus viverra in donec tellus. Integer viverra est eu adipiscing urna in sollicitudin facilisis erat. Donec vestibulum sed nec vel tristique tempus facilisi. Commodo sagittis at pellentesque potenti id parturient vulputate. Tristique varius sed habitasse ac molestie. Arcu facilisi pellentesque consectetur ut lobortis dignissim laoreet magnis.
Phasellus sagittis sollicitudin non est tellus elit sem. Bibendum nec elit turpis tortor vel amet non. Nibh ut eget egestas orci sit aenean nec orci. Ultrices diam hendrerit dictumst phasellus tellus. Netus convallis eu risus placerat. Adipiscing aliquam lectus morbi sem vitae mattis. Molestie egestas commodo tincidunt elementum turpis amet ipsum. Vulputate eget ultrices quis pulvinar leo erat imperdiet adipiscing at. Lacus bibendum cras morbi erat posuere ultrices.
Egestas quis mi tortor enim. Massa non mollis nulla enim et nec netus adipiscing malesuada. Odio est eu lectus tristique tortor facilisis nullam morbi neque. Aliquam ullamcorper risus cras sed eleifend morbi. Quisque netus sollicitudin volutpat fermentum mattis sed semper. Vitae et sed porttitor tellus nisl nisl. Scelerisque mauris odio viverra lorem sem cras molestie aliquam leo. Ultricies nisl amet nunc nunc turpis non velit enim nascetur. Dolor sit donec et vestibulum suspendisse arcu volutpat amet erat.
Quam id auctor eget a quis. Justo in rhoncus tortor vitae. Gravida malesuada nulla iaculis nunc id ultrices ac in tristique. At magna urna adipiscing viverra rhoncus amet facilisi nullam. Morbi nibh nunc semper porttitor venenatis.
Eu gravida tempor elit egestas et. Mauris proin amet pharetra vulputate. Risus nec eu dui vitae tincidunt varius. Sit leo ut purus mauris. Amet molestie mauris proin pellentesque et. Non id congue lectus iaculis pulvinar. Tempor aenean ut tellus pulvinar pellentesque donec aliquam. Convallis pulvinar egestas venenatis leo turpis eget convallis ipsum lacus. Donec ullamcorper iaculis amet lorem egestas. Gravida commodo varius massa ipsum. Placerat gravida duis vitae leo ut diam.
Mi nec varius adipiscing fames neque. Sed praesent est faucibus aliquam sed at urna aliquam. Vitae dignissim mollis neque ipsum. Sed volutpat risus gravida et pretium vulputate eu ultrices eget. Mi nibh libero et viverra.`;
