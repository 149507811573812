// authReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncStage } from '../reduxCommon';
import { Project, Projects } from '../models/project';

interface ProjectState {
	stage: AsyncStage;
	error: string;
	project: Project;
	projects: Projects;
}

const initialState: ProjectState = {
	stage: AsyncStage.NoOp,
	error: '',
	project: {
		id: '',
		created: '',
		updated: '',
		createdBy: {
			id: '',
			name: '',
			date: '',
		},
		name: '',
		namespace: '',
		number: 0,
		type: '',
		status: '',
		driveId: '',
		folderId: '',
		projectInitializationStatus: {
			googleFilesUpdated: false,
			mongoProjectStatus: '',
			googleNamespaceFolderStatus: '',
			googleTemplateFoldersStatus: '',
			googleProjectFilesStatus: '',
			mongoDocumentsStatus: '',
			googleChatSpaceStatus: '',
			googleDriveStatus: '',
		},
		templates: [],
		client: {
			id: '',
			name: '',
			projects: [],
			contacts: [],
			created: '',
			updated: '',
			createdBy: {
				id: '',
				name: '',
				date: '',
			},
		},
	},
	projects: {
		list: [],
		count: 0,
		filter: {
			searchText: '',
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'created', sort: 'desc' }],
			where: {},
		},
		initialized: false,
	},
};

const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		asyncCallStart: (state: ProjectState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: ProjectState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: ProjectState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		refreshProjectSuccess: (
			state: ProjectState,
			action: PayloadAction<Project>
		) => {
			state.project = action.payload;
			state.stage = AsyncStage.Success;
		},
		fetchProjectsSuccess: (
			state: ProjectState,
			action: PayloadAction<{ list: Project[]; count: number }>
		) => {
			state.projects = {
				...state.projects,
				list: action.payload.list,
				count: action.payload.count,
				initialized: true,
			};
			state.stage = AsyncStage.Success;
		},
		updateProjectsFilter: (
			state: ProjectState,
			action: PayloadAction<any>
		) => {
			state.projects = {
				...state.projects,
				filter: {
					...state.projects.filter,
					...action.payload,
				},
			};
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshProjectSuccess,
	fetchProjectsSuccess,
	updateProjectsFilter,
} = projectSlice.actions;

export default projectSlice.reducer;
