import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import { fetchTemplateMappings } from '../services/template-service';
import { TemplateMapping } from '../data/models/template';

const MAX_TEMPLATES = 999;

function useTemplateMappingsList(open: boolean) {
	const { astroToken } = useSelector((state: RootState) => state.authState);
	const [list, setList] = useState<TemplateMapping[]>([]);

	useEffect(() => {
		const fetchTemplateMappingsForDropdown = async () => {
			const templateMappings = await fetchTemplateMappings({
				searchText: '',
				order: 'name',
				direction: 'DESC',
				page: 0,
				take: MAX_TEMPLATES,
				where: {},
			});
			setList(templateMappings.list);
		};
		if (astroToken && open) {
			fetchTemplateMappingsForDropdown();
		}
	}, [astroToken, open]);

	const dropdownOptions = useMemo(() => {
		return sortBy(
			list.map((mapping: any) => ({
				id: mapping.id,
				display: mapping.name,
			})),
			['display']
		);
	}, [list]);

	const dropdownOptionsFullData = useMemo(() => {
		return sortBy(
			list.map((template: TemplateMapping) => ({
				...template,
				id: template.id,
				display: template.name,
			})),
			['display']
		);
	}, [list]);

	return {
		list,
		dropdownOptions,
		dropdownOptionsFullData,
	};
}

export default useTemplateMappingsList;
