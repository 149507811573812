import React from 'react';
import { ProjectStatuses, ProjectTypes } from '../../data/models/project';
import styled from 'styled-components';
import MuiChip from '@mui/material/Chip';
import { Spinner } from '../common/BusySpinner';

interface ProjectTypeDisplayProps {
	type: string;
}

const ProjectTypeDisplay: React.FC<ProjectTypeDisplayProps> = ({ type }) => {
	if (!type) {
		return null;
	}

	const color = ProjectTypes[type]?.color || '#808080';
	const textColor = ProjectTypes[type]?.textColor || '#000000';

	return (
		<TypeChip
			label={ProjectTypes[type]?.display || type}
			chipcolor={color}
			textcolor={textColor}
		/>
	);
};

export default ProjectTypeDisplay;

interface TypeChipProps {
	chipcolor: string;
	textcolor: string;
}

const TypeChip = styled(MuiChip)<TypeChipProps>`
	background-color: ${(props) => props.chipcolor} !important;
	& .MuiChip-label {
		color: ${(props) => props.textcolor} !important;
	}
	height: 24px !important;
`;
