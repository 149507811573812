import { AxiosResponse } from 'axios';
import {
	CompanyTemplate,
	CompanyTemplateDTO,
	EditCompanyTemplateDTO,
	GoogleTemplate,
	Template,
	TemplateMapping,
	TemplateMappingDTO,
	TemplateMappingUpdateDTO,
} from '../data/models/template';
import axiosClient from '../clients/axios-client';
import { stringifyReqObj } from '../utils/common-utils';

export async function fetchCompanyTemplates(filter: any): Promise<{
	list: CompanyTemplate[];
	count: number;
}> {
	const graphqlQuery = `
            {
                companyTemplates(filter: ${stringifyReqObj(filter)}) {
                    ${companyTemplatesROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.companyTemplates;
}

export async function fetchCustomTemplates(): Promise<GoogleTemplate[]> {
	const graphqlQuery = `
            {
                customTemplates {
                    ${customTemplatesROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.customTemplates;
}

export async function fetchTemplateMappings(filter: any): Promise<{
	list: TemplateMapping[];
	count: number;
}> {
	const graphqlQuery = `
            {
                templateMappings(filter: ${stringifyReqObj(filter)}) {
                    ${templateMappingsROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.templateMappings;
}

export async function createCompanyTemplate(
	data: CompanyTemplateDTO
): Promise<CompanyTemplate> {
	const graphqlQuery = `
            mutation {
                createCompanyTemplate(data: ${stringifyReqObj(data)}) {
                    ${companyTemplateROGraphql}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createCompanyTemplate;
}

export async function editCompanyTemplate(
	data: EditCompanyTemplateDTO
): Promise<CompanyTemplate> {
	const graphqlQuery = `
            mutation {
                editCompanyTemplate(data: ${stringifyReqObj(data)}) {
                    ${companyTemplateROGraphql}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.editCompanyTemplate;
}

export async function createTemplateMapping(
	data: TemplateMappingDTO
): Promise<TemplateMapping> {
	const graphqlQuery = `
            mutation {
                createTemplateMapping(data: ${stringifyReqObj(data)}) {
                    ${templateMappingROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createTemplateMapping;
}

export async function editTemplateMapping(
	data: TemplateMappingUpdateDTO
): Promise<TemplateMapping> {
	const graphqlQuery = `
            mutation {
                editTemplateMapping(data: ${stringifyReqObj(data)}) {
                    ${templateMappingROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.editTemplateMapping;
}

export const companyTemplateROGraphql = `
    id
    name
    description
    created
    updated
    mimeType
    projectCategory
    fileSize
    versionHistory {
        version
        googleId
    }
    activeVersion {
        version
        googleId
    }
    active
    default
`;

const companyTemplatesROGraphQl = `
    list {
        ${companyTemplateROGraphql}
    }
    count
`;

const customTemplatesROGraphQl = `
    id
    name
    mimeType
    size
    fileSize
    description
    projectCategory
    activeVersion {
        googleId
    }
`;

const templateMappingROGraphQl = `
    id
    name
    description
    projectType
    budgetRange
    active
    templates {
        ${companyTemplateROGraphql}
    }
`;

const templateMappingsROGraphQl = `
    list {
        ${templateMappingROGraphQl}
    }
    count
`;
