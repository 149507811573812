import React from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

interface TabsProps {
	panels: any[];
	currentPath: string;
}

export default function Tabs({ panels, currentPath }: TabsProps) {
	const activeTab = panels.findIndex((panel) =>
		currentPath.startsWith(panel.key)
	);

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<MuiTabs
					value={activeTab}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="navigation tabs"
				>
					{panels.map((panel, i) => (
						<Tab
							key={i}
							label={panel.label}
							component={Link}
							to={panel.href}
							value={i}
							onClick={panel.onclick}
							sx={{ textTransform: 'none' }}
							{...a11yProps(i)}
						/>
					))}
				</MuiTabs>
			</Box>
			{panels.map((panel, i) => (
				<TabPanel key={i} value={i} activeIndex={activeTab}>
					{panel.component}
				</TabPanel>
			))}
		</>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

interface TabPanelProps {
	children: React.ReactNode;
	value: number;
	activeIndex: number;
}

function TabPanel({ children, value, activeIndex }: TabPanelProps) {
	return (
		<div role="tabpanel" hidden={value !== activeIndex}>
			{value === activeIndex && <div>{children}</div>}
		</div>
	);
}
