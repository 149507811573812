import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import usePagination from '../../hooks/usePaginationStore';
import { fetchProjects } from '../../data/thunks/projectThunk';
import { updateProjectsFilter } from '../../data/stores/projectStore';
import Table, { TableFilterOption } from '../common/Table';
import ProjectStatusDisplay from './ProjectStatusDisplay';
import Link from '../common/Link';
import { useNavigate } from 'react-router-dom';
import GoogleDriveIcon from '../../images/google-drive.png';
import GoogleChatIcon from '../../images/google-chat.png';
import { styled } from 'styled-components';
import {
	ProjectTypes,
	projectStatusOptions,
	projectTypesOptions,
} from '../../data/models/project';
import ProjectTypeDisplay from './ProjectTypeDisplay';

export default function ProjectsTable() {
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate();

	const { projects } = useSelector((state: RootState) => state.projectState);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		setPageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination('projectState', 'projects', updateProjectsFilter);

	useEffect(() => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchProjects(filter));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'created',
			direction: 'DESC',
			page: 0,
			take: pageSize,
			where,
		};
		dispatch(fetchProjects(filter));
	};

	const handleSortOnChange = (order) => {
		setSortModel(order);
		const filter = {
			searchText,
			order: order[0]?.field,
			direction: order[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchProjects(filter));
	};

	const handleOnPageChange = (paginationModel, scrollToTop = false) => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: paginationModel.page,
			take: pageSize,
			where,
		};
		setPage(paginationModel.page);
		dispatch(fetchProjects(filter));
		if (scrollToTop) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const handleFilterOnChange = (field, value) => {
		const newWhere = { ...where };
		if (!!value?.id || !!value) {
			newWhere[field] = value?.id || value;
		} else {
			delete newWhere[field];
		}
		setWhere(newWhere);
		setPage(0);
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: 0,
			take: pageSize,
			where: newWhere,
		};
		dispatch(fetchProjects(filter));
	};

	const projectTypes = projectTypesOptions();
	const projecetStatuses = projectStatusOptions();
	const filterOptions: TableFilterOption[] = [
		{
			field: 'type',
			label: 'Type',
			options: projectTypes.map((type) => ({
				id: type.id,
				display: type.display,
			})),
			value: where.type,
			onChange: handleFilterOnChange,
		},
		{
			field: 'status',
			label: 'Status',
			options: projecetStatuses.map((type) => ({
				id: type.id,
				display: type.display,
			})),
			value: where.status,
			onChange: handleFilterOnChange,
		},
	];

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'namespace',
				headerName: 'Namespace',
				minWidth: 350,
				disableColumnMenu: true,
				renderCell: (params: any) => {
					return (
						<Link
							onClick={() => navigate(`/projects/${params.id}`)}
						>
							{params.value}
						</Link>
					);
				},
			},
			{
				field: 'type',
				headerName: 'Type',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => (
					<ProjectTypeDisplay type={params.value} />
				),
			},
			{
				field: 'status',
				headerName: 'Status',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => (
					<ProjectStatusDisplay status={params.value} />
				),
			},
			{
				field: 'created',
				headerName: 'Created',
				minWidth: 120,
				disableColumnMenu: true,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, YYYY'),
			},
			{
				field: 'links',
				headerName: '',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				// align: 'center',
				renderCell: (params: any) => {
					return (
						<IconContainer>
							{params.row.driveId && params.row.folderId && (
								<img
									style={{
										height: 20,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleDriveIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://drive.google.com/drive/u/${params.row.driveId}/folders/${params.row.folderId}`,
											'_blank'
										)
									}
								/>
							)}
							{params.row.chatSpaceId && (
								<img
									style={{
										height: 20,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleChatIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://mail.google.com/chat/u/2/#chat/space/${params.row.chatSpaceId}`,
											'_blank'
										)
									}
								/>
							)}
						</IconContainer>
					);
				},
			},
		];
	}, []);

	const rows: any[] = useMemo(() => {
		return projects.list.map((project) => ({
			id: project.id,
			namespace: project.namespace,
			type: project.type,
			status: project.status,
			driveId: project.driveId,
			folderId: project.folderId,
			chatSpaceId: project.chatSpaceId
				? project.chatSpaceId.split('/')[1]
				: '',
			created: project.created,
		}));
	}, [projects.list]);

	return (
		<Table
			title={'Projects'}
			columns={columns}
			rows={rows}
			page={page}
			pageSize={pageSize}
			rowCount={projects.count}
			sortModel={sortModel}
			onSortModelChange={handleSortOnChange}
			searchText={searchText}
			handleTextSearch={handleTextSearch}
			onPaginationModelChange={handleOnPageChange}
			filterOptions={filterOptions}
			loading={!projects.initialized}
		/>
	);
}

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 15px;
`;
