import React from 'react';
import Modal from '../common/Modal';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { Controller, useForm } from 'react-hook-form';
import {
	asyncCallDone,
	asyncCallError,
	asyncCallStart,
} from '../../data/stores/projectStore';
import { showAlert } from '../../data/stores/alertStore';
import ScreenBusySpinner from '../common/ScreenBusySpinner';
import Input from '../common/form/Input';
import { AsyncStage } from '../../data/reduxCommon';
import ModalHeader from '../common/ModalHeader';
import { updateCompanyProjectTypes } from '../../services/company-service';
import { refreshCompanySuccess } from '../../data/stores/companyStore';
import { CompanyUpdateProjectTypesDTO } from '../../data/models/company';

interface ProjectTypeCreationModalProps {
	open: boolean;
	onClose: () => void;
}

interface FormData {
	name: string;
}

export default function ProjectTypeCreationModal({
	open,
	onClose,
}: ProjectTypeCreationModalProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { stage } = useSelector((state: RootState) => state.projectState);

	const { handleSubmit, control, reset, formState } = useForm<FormData>({
		defaultValues: {
			name: '',
		},
		mode: 'onSubmit',
	});
	const { errors } = formState;

	const resetForm = () => {
		reset();
	};

	const handleClose = () => {
		onClose();
		resetForm();
	};

	const handleCreateProjectType = async (data: FormData) => {
		dispatch(asyncCallStart());
		try {
			const response = await updateCompanyProjectTypes([
				{
					display: data.name,
					active: true,
				},
			]);
			dispatch(refreshCompanySuccess(response));
			dispatch(
				showAlert({
					message: 'Project type created successfully',
					severity: 'success',
				})
			);
		} catch (error) {
			console.error('error', error);
			dispatch(asyncCallError(error));
			throw error;
		} finally {
			handleClose();
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			{open ? (
				<>
					<ScreenBusySpinner />
					<FormContainer>
						<ModalHeader>Create New Project Type</ModalHeader>
						<InputContainer>
							<Controller
								name="name"
								control={control}
								rules={{ required: 'Name is required' }}
								render={({ field }) => (
									<Input
										{...field}
										label="Type Name"
										id="typeName"
										onChange={(e) => {
											const newValue = e.target.value;
											const regex = /[^A-Za-z0-9 -]/g;
											if (regex.test(newValue)) return;
											field.onChange(newValue);
										}}
										error={errors.name}
										helperText={errors.name?.message}
									/>
								)}
							/>
							<SaveCancelButtons
								handleSave={handleSubmit(
									handleCreateProjectType
								)}
								handleCancel={handleClose}
								saveDisabled={stage === AsyncStage.Busy}
								cancelDisabled={stage === AsyncStage.Busy}
								saveText="Create"
							/>
						</InputContainer>
					</FormContainer>
				</>
			) : null}
		</Modal>
	);
}

const FormContainer = styled.div`
	text-align: center;
`;

const InputContainer = styled.div`
	max-width: 450px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
`;
