import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const BusySpinner = ({ display = 'FULL_SCREEN' }) => {
	if (display === 'FULL_SCREEN') {
		return (
			<OuterContainer>
				<InnerContainer>
					<Spinner />
				</InnerContainer>
			</OuterContainer>
		);
	}
	if (display === 'COMPONENT') {
		return (
			<ComponentContainer>
				<CircularProgress />
			</ComponentContainer>
		);
	}
	return (
		<FullScreenContainer>
			<CircularProgress />
		</FullScreenContainer>
	);
};

export default BusySpinner;

export const Spinner = ({ style = {} }) => {
	return <CircularProgress style={style} />;
};

const FullScreenContainer = styled.div`
	width: 100%;
	text-align: center;
	margin-top: 25vh;
`;

const ComponentContainer = styled.div`
	width: 100%;
	height: 100%;
	text-align: center;
	position: absolute;
	left: 0;
	display: flex;
	right: 0;
	background-color: rgba(255, 255, 255, 0.5);
	align-items: center;
	justify-content: center;
`;

const OuterContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
`;

const InnerContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 200px;
	margin: auto;
	text-align: center;
`;
