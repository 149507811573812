import React from 'react';
import Accordion from '../common/Accordion';
import Paper from '../common/layout/Paper';
import { HeaderContainer, HeaderTitle } from '../common/layout/PageHeader';
import VerticalTabs, { TabPanel } from '../common/VerticalTabs';
import { styled } from 'styled-components';
import Label from '../common/Label';
import Link from '../common/Link';
import { Divider } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function HelpScreen() {
	const [tabValue, setTabValue] = React.useState(0);

	const accordionItems = [
		{
			title: 'How do I create a Project?',
			content:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
		},
		{
			title: 'Why don’t I see my Project?',
			content:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
		},
		{
			title: 'How to create a Client?',
			content:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
		},
	];

	const tabs = [
		{
			label: 'General',
			content: <Accordion items={accordionItems} />,
		},
		{ label: 'Quick Books', content: <div>No, don't do it.</div> },
	];

	return (
		<div>
			<HeaderContainer>
				<HeaderTitle sx={{ marginBottom: 8 }}>
					Questions? We've got answers.
				</HeaderTitle>
			</HeaderContainer>
			<Container>
				<TabContainer>
					<Flex>
						<VerticalTabs
							showTabs={false}
							tabs={tabs}
							value={tabValue}
							setValue={setTabValue}
						/>
					</Flex>
					<StyledPaper>
						<div>
							<NeedMoreHelp>Need more help?</NeedMoreHelp>
							<div>
								We are more than happy to assist you, whenever
								you need us.
							</div>
						</div>
						<div>
							<div>Call us</div>
							<Link>1-800-123-4567</Link>
						</div>
						<div>
							<div>Email us</div>
							<Link>astroai.support@blueox.com</Link>
						</div>
						<Divider />
						<Link
							style={{ display: 'flex', alignContent: 'center' }}
						>
							Create Support Ticket{' '}
							<OpenInNewIcon
								style={{ fontSize: 20, marginLeft: 8 }}
							/>
						</Link>
					</StyledPaper>
				</TabContainer>
				<Flex>
					{tabs.map((tab, index) => (
						<TabPanel key={index} value={tabValue} index={index}>
							{tab.content}
						</TabPanel>
					))}
				</Flex>
			</Container>
		</div>
	);
}

const StyledPaper = styled(Paper)`
	padding: 20px;
	max-width: 300px;
	gap: 15px;
	display: flex;
	flex-direction: column;
`;

const NeedMoreHelp = styled.div`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.text.label};
	margin-bottom: 5px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 80vh;
`;

const TabContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Flex = styled.div`
	flex: 1;
`;
