export interface Template {
	id: string;
	name: string;
	description: string;
	created: Date;
	updated: Date;
	mimeType: string;
	fileSize: number;
	versions: TemplateVersion[];
	active: boolean;
}

export interface CompanyTemplate {
	id: string;
	name: string;
	description: string;
	created: Date;
	updated: Date;
	mimeType: string;
	projectCategory: string;
	fileSize: number;
	versionHistory: TemplateVersion[];
	activeVersion: TemplateVersion;
	default: boolean;
	template: Template;
	templateId: string;
	active: boolean;
}

export interface TemplateMapping {
	id: string;
	name: string;
	description: string;
	created: Date;
	updated: Date;
	projectType: string;
	budgetRange: string;
	active: boolean;
	templates: CompanyTemplate[];
}

export interface TemplateMappingDTO {
	name: string;
	description: string;
	projectType: string;
	budgetRange: string;
	templates: CompanyTemplate[];
}

export interface TemplateMappingUpdateDTO {
	id: string;
	name?: string;
	description?: string;
	projectType?: string;
	budgetRange?: string;
	templates?: string[];
	active?: boolean;
}

export interface CompanyTemplateDTO {
	googleId: string;
	name: string;
	description: string;
	mimeType: string;
	projectCategory: string;
	fileSize: number;
}

export interface EditCompanyTemplateDTO {
	id: string;
	name?: string;
	description: string;
	projectCategory: string;
}

export interface GoogleTemplate {
	id: string;
	name: string;
	mimeType: string;
	size: string;
}

export interface Templates {
	list: CompanyTemplate[];
	count: number;
	filter: {
		searchText: string;
		page: number;
		pageSize: number;
		sortModel: any;
		where: object;
	};
	initialized: boolean;
}

export interface CustomTemplates {
	list: any[];
	initialized: boolean;
}

export interface TemplateMappings {
	list: any[];
	count: number;
	filter: {
		searchText: string;
		page: number;
		pageSize: number;
		sortModel: any;
		where: object;
	};
	initialized: boolean;
}

// export interface TemplateMapping {
// 	list: any[];
// 	initialized: boolean;
// }

export interface CompanyTemplate {
	id: string;
	name: string;
	description: string;
	projectCategory: string;
	created: Date;
	updated: Date;
	templateId: string;
	activeVersion: TemplateVersion;
	active: boolean;
}

export interface TemplateVersion {
	version: number;
	googleId: string;
}

export function getReadableGoogleMimeType(mimeType: string): string {
	const mimeTypeMap: { [key: string]: string } = {
		'application/vnd.google-apps.document': 'Google Docs',
		'application/vnd.google-apps.spreadsheet': 'Google Sheets',
		'application/vnd.google-apps.presentation': 'Google Slides',
		'application/vnd.google-apps.drawing': 'Google Drawings',
		'application/vnd.google-apps.form': 'Google Forms',
		'application/vnd.google-apps.script': 'Google Apps Script',
		'application/vnd.google-apps.map': 'Google My Maps',
		'application/vnd.google-apps.site': 'Google Sites',
		'application/vnd.google-apps.folder': 'Google Drive Folder',
		'application/vnd.google-apps.shortcut': 'Google Drive Shortcut',
		'application/vnd.google-apps.photo': 'Google Photos',
		'application/vnd.google-apps.video': 'Google Videos',
		'application/vnd.google-apps.audio': 'Google Audio',
		'application/vnd.google-apps.file': 'Google Drive File',
		'application/vnd.google-apps.unknown': 'Unknown Google Drive File',
		'application/vnd.google-apps.fusiontable': 'Google Fusion Table',
		'application/vnd.google-apps.jam': 'Google Jamboard',
		'application/vnd.google-apps.drive-sdk': 'Third-party App File',
	};

	return mimeTypeMap[mimeType] || 'Unknown Google App Type';
}
