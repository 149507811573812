import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { fetchUsers } from '../services/user-service';
import { User } from '../data/models/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import { fetchCompany } from '../data/thunks/companyThunk';

function useCompany() {
	const dispatch = useDispatch<AppDispatch>();
	const { astroToken } = useSelector((state: RootState) => state.authState);
	const { company } = useSelector((state: RootState) => state.companyState);

	useEffect(() => {
		const fetchCompanyData = async () => {
			dispatch(fetchCompany());
		};
		if (astroToken) {
			fetchCompanyData();
		}
	}, [astroToken]);

	return {
		company,
		projectTypeOptions: company.projectTypes.filter((pt) => pt.active),
	};
}

export default useCompany;
