// store.ts
import { combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
	persistStore as createPersistStore,
	persistReducer as createPersistReducer,
	createMigrate,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, Action } from '@reduxjs/toolkit';
import authReducer from './stores/authStore';
import alertReducer from './stores/alertStore';
import clientReducer from './stores/clientStore';
import projectReducer from './stores/projectStore';
import companyReducer from './stores/companyStore';
import modalReducer from './stores/modalStore';
import templateReducer from './stores/templateStore';

const CURRENT_VERSION = 1;

const migrations = {
	// If needed, add migration functions here.
	// They should take the previous state and return the new state.
};

const persistConfig = {
	key: 'astro-web',
	storage,
	whitelist: ['authState'],
	version: CURRENT_VERSION,
	migrate: createMigrate(migrations, {
		debug: process.env.NODE_ENV === 'development',
	}),
};

const rootReducer = combineReducers({
	authState: authReducer,
	alertState: alertReducer,
	clientState: clientReducer,
	projectState: projectReducer,
	companyState: companyReducer,
	modalState: modalReducer,
	templateState: templateReducer,
});

const persistedReducer = createPersistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

export default store;

export const persistStore = createPersistStore(store);
