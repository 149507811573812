import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import { Project, projectTypesOptions } from '../../../data/models/project';
import ModalHeader from '../../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
	asyncCallDone,
	asyncCallStart,
	refreshProjectSuccess,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
import Autocomplete, {
	AutocompleteOption,
} from '../../common/form/AutoComplete';
import {
	addTemplatesToProject,
	updateProject,
} from '../../../services/project-service';
import { RootState } from '../../../data/store';
import { fetchCompanyTemplates } from '../../../services/template-service';
import { CompanyTemplate } from '../../../data/models/template';
import { FlexCenterRow } from '../../common/layout/Content';
import { getIconFromGoogleMimeType } from '../../../utils/company-utils';
import Button from '../../common/buttons/Button';
import AddIcon from '@mui/icons-material/Add';
import UndoIcon from '@mui/icons-material/Undo';
import isEmpty from 'lodash/isEmpty';

interface ProjectAddTemplateModalProps {
	open: boolean;
	handleClose: () => void;
}
export default function ProjectAddTemplateModal(
	props: ProjectAddTemplateModalProps
) {
	const { open, handleClose } = props;

	const dispatch = useDispatch();

	// const { templates } = useSelector(
	// 	(state: RootState) => state.templateState
	// );
	const {
		project: { templates: projectTemplates, id: projectId, namespace },
	} = useSelector((state: RootState) => state.projectState);
	const [companyTemplates, setCompanyTemplates] = useState<CompanyTemplate[]>(
		[]
	);
	const [templatesToAdd, setTemplatesToAdd] = useState<any>({});

	useEffect(() => {
		const initCompanyTemplates = async () => {
			const response = await fetchCompanyTemplates({});
			setCompanyTemplates(response.list);
		};
		if (open) {
			initCompanyTemplates();
		}
	}, [open]);

	const availableTemplates = useMemo(() => {
		// console.log('companyTemplates', companyTemplates);
		// console.log('projectTemplates', projectTemplates);
		return companyTemplates.filter((companyTemplate) => {
			return !projectTemplates.some((projectTemplate) => {
				return projectTemplate.id === companyTemplate.id;
			});
		});
	}, [companyTemplates, projectTemplates]);
	// console.log('availableTemplates', availableTemplates);

	const handleOnClose = () => {
		setTemplatesToAdd({});
		handleClose();
	};

	const handleAddTemplateToggle = (templateId: string) => {
		setTemplatesToAdd((prev) => {
			const newTemplates = { ...prev };
			if (newTemplates[templateId]) {
				delete newTemplates[templateId];
			} else {
				newTemplates[templateId] = true;
			}
			return newTemplates;
		});
	};

	const handleSubmit = async () => {
		try {
			handleOnClose();
			dispatch(asyncCallStart());
			const updatedProject = await addTemplatesToProject(
				projectId,
				Object.keys(templatesToAdd)
			);
			dispatch(refreshProjectSuccess(updatedProject));
			dispatch(
				showAlert({
					message: 'Templates enqueued to be added to project.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred adding templates to project.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal
			open={open}
			handleClose={handleOnClose}
			sx={{ minWidth: 650, overflow: 'scroll' }}
		>
			<ModalHeader>Inactive Templates</ModalHeader>
			<Label>Name</Label>
			<Info>{namespace}</Info>
			<AvailableTemplates
				templates={availableTemplates}
				templatesToAdd={templatesToAdd}
				handleAddTemplateToggle={handleAddTemplateToggle}
			/>
			<SaveCancelContainer>
				<SaveCancelButtons
					handleSave={handleSubmit}
					handleCancel={handleOnClose}
					saveText={'Submit'}
					saveDisabled={isEmpty(templatesToAdd)}
				/>
			</SaveCancelContainer>
		</Modal>
	);
}

const AvailableTemplates = (props) => {
	const { templates, templatesToAdd, handleAddTemplateToggle } = props;

	return (
		<div>
			{templates.map((t) => (
				<TemplateRow
					key={t.id}
					template={t}
					added={templatesToAdd[t.id]}
					handleAddTemplateToggle={() =>
						handleAddTemplateToggle(t.id)
					}
				/>
			))}
		</div>
	);
};

const TemplateRow = (props) => {
	const { template, added, handleAddTemplateToggle } = props;
	const icon = getIconFromGoogleMimeType(template.mimeType);

	return (
		<TemplateRowContainer>
			<FlexCenterRow>
				<img
					style={{
						height: 20,
						marginRight: 10,
						objectFit: 'cover',
					}}
					src={icon.icon}
					alt={icon.alt}
				/>
				{template.name}
			</FlexCenterRow>
			<FlexCenterRow>
				{added ? (
					<FlexCenterRow>
						Added{' '}
						<UndoButton
							onClick={handleAddTemplateToggle}
							variant={'text'}
							color={'error'}
						>
							<UndoIcon style={{ marginRight: 10 }} /> Undo
						</UndoButton>
					</FlexCenterRow>
				) : (
					<AddToProjectButton
						onClick={handleAddTemplateToggle}
						variant={'text'}
					>
						<AddIcon style={{ marginRight: 3 }} /> Add to Project
					</AddToProjectButton>
				)}
			</FlexCenterRow>
		</TemplateRowContainer>
	);
};

const UndoButton = styled(Button)`
	text-transform: none !important;
	font-weight: normal !important;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.palette.error.main};
	cursor: pointer;
	margin-left: 7px !important;
`;

const AddToProjectButton = styled(Button)`
	text-transform: none !important;
	font-weight: normal !important;
	text-wrap: nowrap;
	margin-left: 10px !important;
`;

const TemplateRowContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid ${({ theme }) => theme.palette.text.divider};
`;

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
