import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as TemplateService from '../../services/template-service';
import { showAlert } from '../stores/alertStore';
import {
	asyncCallDone,
	asyncCallError,
	asyncCallStart,
	refreshTemplates,
	refreshCustomTemplates,
	updateCustomTemplate,
	refreshTemplateMappings,
} from '../stores/templateStore';
import { CompanyTemplateDTO, EditCompanyTemplateDTO } from '../models/template';

export const fetchCompanyTemplates =
	(filter: any): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await TemplateService.fetchCompanyTemplates(
				filter
			);
			dispatch(refreshTemplates(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			return dispatch(
				showAlert({
					message: 'An error occurred while fetching the Templates',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

export const fetchCustomTemplates =
	(): AppThunk => async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await TemplateService.fetchCustomTemplates();
			dispatch(refreshCustomTemplates(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Templates',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

export const fetchTemplateMappings =
	(filter: any): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await TemplateService.fetchTemplateMappings(
				filter
			);
			dispatch(refreshTemplateMappings(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message:
						'An error occurred while fetching the Template Mappings',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

// export const createCompanyTemplate =
// 	(data: CompanyTemplateDTO): AppThunk =>
// 	async (dispatch: Dispatch) => {
// 		dispatch(asyncCallStart());
// 		try {
// 			const response = await TemplateService.createCompanyTemplate(data);
// 			dispatch(refreshCustomTemplates(response));
// 			dispatch(
// 				showAlert({
// 					message: 'Successfully created the Template.',
// 					severity: 'success',
// 				})
// 			);
// 		} catch (error) {
// 			dispatch(asyncCallError(error));
// 			dispatch(
// 				showAlert({
// 					message: 'An error occurred while fetching the Templates',
// 					severity: 'error',
// 				})
// 			);
// 		}
// 		dispatch(asyncCallDone());
// 	};

export const editCompanyTemplate =
	(data: EditCompanyTemplateDTO): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await TemplateService.editCompanyTemplate(data);
			dispatch(updateCustomTemplate(response));
			dispatch(
				showAlert({
					message: 'Successfully updated the Template.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Templates',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};
