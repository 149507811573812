import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	CompanyTemplate,
	CustomTemplates,
	GoogleTemplate,
	Template,
	TemplateMapping,
	TemplateMappings,
	TemplateMappingUpdateDTO,
	Templates,
} from '../models/template';
import { AsyncStage } from '../reduxCommon';

interface TemplateState {
	stage: AsyncStage;
	templates: Templates;
	customTemplates: CustomTemplates;
	templateMappings: TemplateMappings;
	error: any;
}

const initialState: TemplateState = {
	stage: AsyncStage.NoOp,
	templates: {
		list: [],
		count: 0,
		filter: {
			searchText: '',
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'created', sort: 'desc' }],
			where: {},
		},
		initialized: false,
	},
	customTemplates: {
		list: [],
		initialized: false,
	},
	templateMappings: {
		list: [],
		count: 0,
		filter: {
			searchText: '',
			page: 0,
			pageSize: 50,
			sortModel: [{ field: 'created', sort: 'desc' }],
			where: {},
		},
		initialized: false,
	},
	error: '',
};

export const templateSlice = createSlice({
	name: 'Template',
	initialState,
	reducers: {
		asyncCallStart: (state: TemplateState) => {
			state.stage = AsyncStage.Busy;
		},
		asyncCallDone: (state: TemplateState) => {
			state.stage = AsyncStage.NoOp;
		},
		asyncCallError: (state: TemplateState, action: PayloadAction<any>) => {
			state.stage = AsyncStage.Error;
			state.error = action.payload.message || 'UNKNOWN';
		},
		refreshTemplates: (
			state,
			action: PayloadAction<{
				list: CompanyTemplate[];
				count: number;
			}>
		) => {
			state.templates = {
				...state.templates,
				list: action.payload.list,
				count: action.payload.count,
				initialized: true,
			};
		},
		refreshCustomTemplates: (
			state,
			action: PayloadAction<GoogleTemplate[]>
		) => {
			state.customTemplates = {
				...state.customTemplates,
				list: action.payload,
				initialized: true,
			};
		},
		convertCustomTemplateToCompanyTemplate: (
			state,
			action: PayloadAction<CompanyTemplate>
		) => {
			state.customTemplates.list = state.customTemplates.list.map((t) =>
				t.id === action.payload.activeVersion.googleId
					? action.payload
					: t
			);
		},
		refreshTemplateMappings: (
			state,
			action: PayloadAction<{ list: TemplateMapping[]; count: number }>
		) => {
			state.templateMappings = {
				...state.templateMappings,
				list: action.payload.list,
				count: action.payload.count,
				initialized: true,
			};
		},
		addTemplateMapping: (state, action: PayloadAction<TemplateMapping>) => {
			state.templateMappings = {
				...state.templateMappings,
				list: [action.payload, ...state.templateMappings.list],
			};
		},
		updateTemplateMappingInList: (
			state,
			action: PayloadAction<TemplateMapping>
		) => {
			state.templateMappings = {
				...state.templateMappings,
				list: state.templateMappings.list.map((t) =>
					t.id === action.payload.id ? action.payload : t
				),
			};
		},
		updateCustomTemplate: (
			state,
			action: PayloadAction<CompanyTemplate>
		) => {
			// edit the template in the list by id
			const index = state.customTemplates.list.findIndex(
				(t) => t.id === action.payload.id
			);
			state.customTemplates.list[index] = action.payload;
			state.customTemplates = {
				...state.customTemplates,
				list: state.customTemplates.list,
			};
		},
		hideTemplate: (state) => {
			// state.open = false;
			// state.message = '';
			// state.severity = 'info';
		},
		updateTemplatesFilter: (
			state: TemplateState,
			action: PayloadAction<any>
		) => {
			state.templates = {
				...state.templates,
				filter: {
					...state.templates.filter,
					...action.payload,
				},
			};
		},
	},
});

export const {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	refreshTemplates,
	refreshCustomTemplates,
	convertCustomTemplateToCompanyTemplate,
	refreshTemplateMappings,
	addTemplateMapping,
	updateTemplateMappingInList,
	updateCustomTemplate,
	hideTemplate,
	updateTemplatesFilter,
} = templateSlice.actions;

export default templateSlice.reducer;
