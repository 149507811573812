import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface ToggleButtonProps {
	value: any;
	onChange: (arg0: any) => void;
	options: any[];
	disabled?: boolean;
}
export default function ToggleButtonComponent(props: ToggleButtonProps) {
	const { value, onChange, options, disabled } = props;

	const handleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
		if (!value && value !== false) return;
		onChange(value);
	};

	return (
		<ToggleButtonGroup
			color="primary"
			value={value}
			exclusive
			onChange={handleChange}
			disabled={disabled}
			aria-label="Contents/Structure"
		>
			{options.map((option) => (
				<ToggleButton
					style={{ height: 35, whiteSpace: 'nowrap' }}
					value={option.value}
					key={option.value}
				>
					{option.display}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
}
