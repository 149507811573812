import { AxiosResponse } from 'axios';
import axiosClient from '../clients/axios-client';
import { stringifyReqObj } from '../utils/common-utils';
import { Project, ProjectDTO, ProjectsFilter } from '../data/models/project';
import { companyTemplateROGraphql } from './template-service';

export async function createProject(data: ProjectDTO): Promise<Project> {
	const graphqlQuery = `
            mutation {
                createProject(
                    data: ${stringifyReqObj(data)},
                ) {
                    id
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createProject;
}

export async function fetchProject(id: string): Promise<Project> {
	const graphqlQuery = `
            {
                project(
                    id: "${id}",
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.project;
}

export async function updateProject(
	projectId: string,
	data: { type: string }
): Promise<Project> {
	const graphqlQuery = `
            mutation {
                updateProject(
                    projectId: "${projectId}",
                    data: ${stringifyReqObj(data)},
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateProject;
}

export async function updateProjectName(
	projectId: string,
	name: string
): Promise<Project> {
	const graphqlQuery = `
            mutation {
                updateProjectName(
                    projectId: "${projectId}",
                    name: "${name}",
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateProjectName;
}

export async function updateProjectClient(
	projectId: string,
	clientId: string
): Promise<Project> {
	const graphqlQuery = `
            mutation {
                updateProjectClient(
                    projectId: "${projectId}",
                    clientId: "${clientId}",
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateProjectClient;
}

export async function fetchProjects(
	filter: ProjectsFilter
): Promise<{ list: Project[]; count: number }> {
	const graphqlQuery = `
            {
                projects(
                    filter: ${stringifyReqObj(filter)},
                ) {
                    ${projectListROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});

	return response?.data?.projects;
}

export async function fetchNextProjectNumber(): Promise<number> {
	const graphqlQuery = `
            {
                fetchNextProjectNumber
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});

	return response?.data?.fetchNextProjectNumber;
}

export async function archiveProject(projectId: string): Promise<Project> {
	const graphqlQuery = `
            mutation {
                archiveProject(
                    projectId: "${projectId}",
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.archiveProject;
}

export async function unarchiveProject(projectId: string): Promise<Project> {
	const graphqlQuery = `
            mutation {
                unarchiveProject(
                    projectId: "${projectId}",
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.unarchiveProject;
}

export async function addTemplatesToProject(
	projectId: string,
	templates: string[]
): Promise<Project> {
	const graphqlQuery = `
            mutation {
                addTemplatesToProject(
                    projectId: "${projectId}",
                    templates: ${stringifyReqObj(templates)},
                ) {
                    ${projectROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.addTemplatesToProject;
}

const projectListROGraphQl = `
    list {
        id
        created
        updated
        name
        namespace
        number
        type
        status
        createdBy {
            name
            id
        }
    }
    count
`;

const projectROGraphQl = `
    id
    created
    updated
    createdBy {
        name
    }
    archivedOn
    name
    namespace
    number
    type
    status
    clientId
    client {
        id
        name
    }
    companyId
    driveId
    folderId
    chatSpaceId
    projectInitializationStatus {
        googleNamespaceFolderStatus
        googleTemplateFoldersStatus
        googleProjectFilesStatus
        mongoDocumentsStatus
        googleChatSpaceStatus
        googleFilesUpdated
        googleDriveStatus
    }
    projectInitializationFolderStatuses
    projectInitializationFileStatuses
    projectUpdateStatus
    templates {
        ${companyTemplateROGraphql}
    }
`;
