import * as React from 'react';
import FormControlLabel, {
	FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

// @ts-ignore
interface CustomSwitchProps extends Omit<FormControlLabelProps, 'control'> {
	label: string;
	checked?: boolean;
	onChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	disabled?: boolean;
	required?: boolean;
	switchProps?: SwitchProps;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
	label,
	checked = false,
	onChange,
	disabled = false,
	required = false,
	switchProps,
	...labelProps
}) => {
	return (
		<FormControlLabel
			control={
				<Switch
					checked={checked}
					onChange={onChange}
					disabled={disabled}
					{...switchProps}
				/>
			}
			label={required ? `${label} *` : label}
			{...labelProps}
		/>
	);
};

export default CustomSwitch;
