import React from 'react';
import styled from 'styled-components';
import MUIBox from '@mui/material/Box';
import MUIGrid from '@mui/material/Grid';

export const Box = styled(MUIBox)``;

export const Grid = styled(MUIGrid)``;

export const FlexCenterRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
