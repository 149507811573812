import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../common/Modal';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import ModalHeader from '../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { closeModal } from '../../data/stores/modalStore';
import Input from '../common/form/Input';
import RadioButtons from '../common/buttons/RadioButtons';
// import { createCompanyTemplate } from '../../data/thunks/templateThunk';
import {
	asyncCallStart,
	asyncCallDone,
	asyncCallError,
	convertCustomTemplateToCompanyTemplate,
} from '../../data/stores/templateStore';
import { createCompanyTemplate } from '../../services/template-service';
import { showAlert } from '../../data/stores/alertStore';

export default function AddCustomTemplateModal() {
	const {
		openId,
		props: { googleTemplate },
	} = useSelector((state: RootState) => state.modalState);

	const open = openId === 'addCustomTemplateModal';

	const dispatch = useDispatch<AppDispatch>();

	const [description, setDescription] = useState('');
	const [projectCategory, setProjectCategory] = useState('');

	const handleClose = () => {
		dispatch(closeModal());
	};

	const onSave = async () => {
		if (googleTemplate) {
			dispatch(asyncCallStart());
			try {
				const response = await createCompanyTemplate({
					googleId: googleTemplate.id,
					name: googleTemplate.name,
					description,
					projectCategory,
					mimeType: googleTemplate.mimeType,
					fileSize: googleTemplate.fileSize,
				});
				dispatch(convertCustomTemplateToCompanyTemplate(response));
				dispatch(
					showAlert({
						message: 'Successfully created the Template.',
						severity: 'success',
					})
				);
			} catch (error) {
				dispatch(asyncCallError(error));
				dispatch(
					showAlert({
						message:
							'An error occurred while fetching the Templates',
						severity: 'error',
					})
				);
			}
			dispatch(asyncCallDone());
		}
		handleClose();
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<Container>
				<ModalHeader>Add Custom Template</ModalHeader>
				<Input
					label={'Name'}
					value={googleTemplate?.name}
					id="name"
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					disabled
					style={{ marginBottom: 20 }}
					// helperText={errors.name?.message}
				/>
				<Input
					label={'Description'}
					id="description"
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					multiline
					rows={3}
					maxRows={3}
					// helperText={errors.name?.message}
				/>
				<ProjectCategoryContainer>
					<RadioButtons
						label="Project Category"
						name="projectCategory"
						options={[
							{ value: 'CREATIVE', label: 'Creative' },
							{
								value: 'LEGAL_AND_CONTRACT',
								label: 'Legal and Contract',
							},
							{
								value: 'PRODUCTION',
								label: 'Production',
							},
							{
								value: 'POST_PRODUCTION',
								label: 'Post-Production',
							},
						]}
						onChange={(e) => {
							setProjectCategory(e.target.value);
						}}
						value={projectCategory}
					/>
				</ProjectCategoryContainer>
				<SaveCancelContainer>
					<SaveCancelButtons
						handleSave={onSave}
						handleCancel={handleClose}
						saveText={'Create'}
						saveDisabled={!description || !projectCategory}
					/>
				</SaveCancelContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	min-width: 400px;
`;

const ProjectCategoryContainer = styled.div`
	margin-top: 20px;
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
