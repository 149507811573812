import React, { useEffect } from 'react';
import { HeaderTitle } from '../common/layout/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { fetchCompany } from '../../data/thunks/companyThunk';
import QuickLinks from './CompanyQuickLinks';
import CompanyProjectTypes from './CompanyProjectTypes';
import { Grid } from '../common/layout/Content';
import ScreenContainer from '../common/layout/ScreenContainer';

export default function CompanyScreen() {
	const dispatch = useDispatch<AppDispatch>();
	useEffect(() => {
		dispatch(fetchCompany());
	}, []);

	const {
		company: { quickLinks, initialized, projectTypes },
	} = useSelector((state: RootState) => state.companyState);

	return (
		<ScreenContainer>
			{/* <HeaderTitle>My Company</HeaderTitle> */}
			<Grid container spacing={3}>
				<Grid item xs={12} md={12}>
					<QuickLinks links={quickLinks} initialized={initialized} />
				</Grid>
				<Grid item xs={12} md={12}>
					<CompanyProjectTypes
						projectTypes={projectTypes}
						initialized={initialized}
					/>
				</Grid>
			</Grid>
		</ScreenContainer>
	);
}
