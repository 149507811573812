import { Client } from './client';
import { CompanyTemplate } from './template';
import { DatedUserReference } from './user';

export interface Project {
	id: string;
	number: number;
	name: string;
	namespace: string;
	type: string;
	status: string;
	driveId?: string;
	folderId?: string;
	created: string;
	updated: string;
	archivedOn?: string;
	createdBy: DatedUserReference;
	projectInitializationStatus: ProjectInitializationStatus;
	projectInitializationFileStatuses?: any;
	chatSpaceId?: string;
	templates: CompanyTemplate[];
	client: Client;
}

export interface Projects {
	list: Project[];
	count: number;
	filter: {
		searchText: string;
		page: number;
		pageSize: number;
		sortModel: any;
		where: object;
	};
	initialized: boolean;
}

export interface ProjectsFilter {
	searchText: string;
	page: number;
	order: string;
	direction: string;
	take: number;
	where: ProjectsWhereFilter;
}

export interface ProjectsWhereFilter {
	statusNotIn?: string[];
	status?: string[];
}

export interface ProjectDTO {
	name: string;
	clientId: string;
	type: string;
	budgetRange: string;
	companyTemplateMappingId: string;
	workspaceMembers: string[];
}

export interface ProjectInitializationStatus {
	mongoProjectStatus: string;
	googleFilesUpdated: boolean;
	googleNamespaceFolderStatus: string;
	googleTemplateFoldersStatus: string;
	googleProjectFilesStatus: string;
	mongoDocumentsStatus: string;
	googleChatSpaceStatus: string;
	googleDriveStatus: string;
}

export const ProjectStatuses = {
	INITIALIZING: {
		id: 'INITIALIZING',
		display: 'Initializing',
	},
	ACTIVE: {
		id: 'ACTIVE',
		display: 'Active',
	},
	UPDATING: {
		id: 'UPDATING',
		display: 'Updating',
	},
	ERROR: {
		id: 'ERROR',
		display: 'Error',
	},
	ARCHIVED: {
		id: 'ARCHIVED',
		display: 'Archived',
	},
	CLOSED: {
		id: 'CLOSED',
		display: 'Closed',
	},
};

export const projectStatusOptions = () => {
	return ['ACTIVE', 'UPDATING', 'ARCHIVED'].map(
		(status) => ProjectStatuses[status]
	);
};

export const ProjectItemCreationStatuses = {
	INITIALIZING: {
		id: 'INITIALIZING',
		display: 'Initializing',
	},
	CREATED: {
		id: 'CREATED',
		display: 'Created',
	},
	CREATION_ERROR: {
		id: 'CREATION_ERROR',
		display: 'Error Creating',
	},
	UPDATE_ERROR: {
		id: 'UPDATE_ERROR',
		display: 'Error Updating',
	},
	ERROR: {
		id: 'ERROR',
		display: 'Error',
	},
	SUCCESS: {
		id: 'SUCCESS',
		display: 'Success',
	},
};

export const ProjectTypes = {
	BEHIND_THE_SCENES: {
		id: 'BEHIND_THE_SCENES',
		display: 'Behind the Scenes',
		color: '#808080',
	},
	BRANDED: {
		id: 'BRANDED',
		display: 'Branded',
		color: '#F6CDAE',
		textColor: '#673911',
	},
	CORP_COMMS: {
		id: 'CORP_COMMS',
		display: 'Corp/Comms',
		color: '#808080',
	},
	CREATIVE: {
		id: 'CREATIVE',
		display: 'Creative',
		color: '#CBF6B8',
		textColor: '#070B08',
	},
	DOCUMENTARY_FEATURE: {
		id: 'DOCUMENTARY_FEATURE',
		display: 'Documentary Feature',
		color: '#808080',
	},
	DOCUMENTARY_SERIES: {
		id: 'DOCUMENTARY_SERIES',
		display: 'Documentary Series',
		color: '#808080',
	},
	DOCUMENTARY_SHORT: {
		id: 'DOCUMENTARY_SHORT',
		display: 'Documentary Short',
		color: '#E7E7E7',
		textColor: '#464646',
	},
	EVENT: {
		id: 'EVENT',
		display: 'Event',
		color: '#F6D1CB',
		textColor: '#912B21',
	},
	FITNESS: {
		id: 'FITNESS',
		display: 'Fitness',
		color: '#D9ECBB',
		textColor: '#40724F',
	},
	FUNDRAISING: {
		id: 'FUNDRAISING',
		display: 'Fundraising',
		color: '#808080',
	},
	GEAR_RENTAL: {
		id: 'GEAR_RENTAL',
		display: 'Gear Rental',
		color: '#808080',
	},
	MUSIC: {
		id: 'MUSIC',
		display: 'Music',
		color: '#808080',
	},
	PHOTO: {
		id: 'PHOTO',
		display: 'Photo',
		color: '#E2D1F1',
		textColor: '#533B7B',
	},
	PODCAST: {
		id: 'PODCAST',
		display: 'Podcast',
		color: '#808080',
	},
	PRODUCTION: {
		id: 'PRODUCTION',
		display: 'Production',
		color: '#CDD9E6',
		textColor: '#3B565C',
	},
	REAL_ESTATE: {
		id: 'REAL_ESTATE',
		display: 'Real Estate',
		color: '#808080',
	},
	SCRIPTED: {
		id: 'SCRIPTED',
		display: 'Scripted',
		color: '#808080',
	},
	SIZZLE: {
		id: 'SIZZLE',
		display: 'Sizzle',
		color: '#F7E7A8',
		textColor: '#4C3A1B',
	},
	SOCIAL: {
		id: 'SOCIAL',
		display: 'Social',
		color: '#C6E3F6',
		textColor: '#305788',
	},
	TRAILER: {
		id: 'TRAILER',
		display: 'Trailer',
		color: '#808080',
	},
	VFX_GFX: {
		id: 'VFX_GFX',
		display: 'VFX/GFX',
		color: '#808080',
	},
};

export const projectTypesOptions = () => {
	return Object.values(ProjectTypes);
};

export const ProjectCategories = {
	CREATIVE: { id: 'CREATIVE', display: 'Creative' },
	LEGAL_AND_CONTRACT: {
		id: 'LEGAL_AND_CONTRACT',
		display: 'Legal and Contract',
	},
	PRODUCTION: {
		id: 'PRODUCTION',
		display: 'Production',
	},
	POST_PRODUCTION: {
		id: 'POST_PRODUCTION',
		display: 'Post-Production',
	},
};

export const projectCategoryOptions = () => {
	return Object.values(ProjectCategories);
};

export const BudgetRanges = {
	'0_1000': {
		id: '0_1000',
		display: '$0 - $1,000',
	},
	'1000_5000': {
		id: '1000_5000',
		display: '$1,000 - $5,000',
	},
	'5000_10000': {
		id: '5000_10000',
		display: '$5,000 - $10,000',
	},
	'10000_50000': {
		id: '10000_50000',
		display: '$10,000 - $50,000',
	},
	'50000_100000': {
		id: '50000_100000',
		display: '$50,000 - $100,000',
	},
	'100000_500000': {
		id: '100000_500000',
		display: '$100,000 - $500,000',
	},
	'500000_1000000': {
		id: '500000_1000000',
		display: '$500,000 - $1,000,000',
	},
	'1000000_2500000': {
		id: '1000000_2500000',
		display: '$1,000,000 - $2,500,000',
	},
	'2500000': {
		id: '2500000',
		display: '$2,500,000+',
	},
};

export const budgetRangeOptions = () => {
	return Object.values(BudgetRanges);
};
