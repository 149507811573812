import React from 'react';
import { Modal as MuiModal, Fade, Box } from '@mui/material';

interface ModalProps {
	open: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	sx?: any;
}

const Modal: React.FC<ModalProps> = ({
	open,
	handleClose,
	children,
	sx = {},
}) => {
	return (
		<MuiModal open={open} onClose={handleClose}>
			<Fade
				in={open}
				timeout={{
					enter: 500,
					exit: 0,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						minWidth: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: 4,
						maxHeight: '90%',
						...sx,
					}}
				>
					{children}
				</Box>
			</Fade>
		</MuiModal>
	);
};

export default Modal;
